module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Balansekunst: Samtalekort","short_name":"Samtalekort","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/assets/images/favicon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"d219bd06a4e7458dc17ef934c530d98c"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"6","matomoUrl":"//a.444.no","siteUrl":"https://samtalekort.balansekunst.no","matomoJsScript":"js/","disableCookies":true,"dev":false},
    },{
      plugin: require('../../../node_modules/@herob191/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["no","en"],"defaultLanguage":"no","redirect":false,"siteUrl":"https://samtalekort.balansekunst.no/","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true,"excludeLanguages":["no"]},{"matchPath":"/","languages":["no","en"]}]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
